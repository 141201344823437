@import 'src/styles/utils';

.root {
  position: sticky;
  z-index: 3;
  top: 48px;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 16px 0 8px;
  border-bottom: 1px solid #d6d6d6;
  background-color: #fff;
}

.mx_16 {
  margin: 0 16px;
}
