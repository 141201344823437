.root {
  display: flex;
  justify-content: center;
}

.button {
  flex: 0 0 86px;
  height: 29px;
  border: solid 1px #333;
  background-color: transparent;
  color: #333;
  font-size: 11px;
  line-height: 1;
  appearance: none;
  cursor: pointer;

  &:first-child {
    border-right: none;
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-left: none;
    border-radius: 0 4px 4px 0;
  }

  &.isSelected {
    background-color: #333;
    color: white;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}
