@import 'src/styles/utils';

.MerchantCalendarCard {
  $opacity: 0.3;

  display: block;
  position: relative;
  box-sizing: border-box;
  height: 89px;
  margin-top: 7px;
  padding: 5px 5px 7px;
  border: solid 1px #dedede;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  text-decoration: none;

  &:first-child {
    margin-top: 0;
  }

  &.isFull {
    border-color: rgba(#dedede, $opacity);
    background-color: rgba(white, $opacity);
    pointer-events: none;
  }

  &.isList {
    display: flex;
    align-items: center;
    height: auto;
  }

  &.notReservable {
    opacity: 0.5;
  }

  .MerchantCalendarCard__Accent {
    display: block;
    height: 4px;
    margin: 0 -2px;
    border-radius: 4px;

    &.isFull {
      opacity: $opacity;
    }

    &.isList {
      flex-shrink: 0;
      width: 4px;
      height: 100%;
      min-height: 50px;
      margin: 0;
    }
  }

  .MerchantCalendarCard__Text {
    &.isFull {
      opacity: $opacity;
    }

    &.isList {
      flex-grow: 1;
      margin-left: 6px;
    }
  }

  .MerchantCalendarCard__Time {
    display: block;
    margin-top: 7px;
    font-family: 'DIN Alternate', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
  }

  .MerchantCalendarCard__Title {
    margin: 3px 0 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.45;

    &.isList {
      font-size: 13px;
    }
  }

  .MerchantCalendarCard__Status {
    display: inline-block;
    position: absolute;
    bottom: 3px;
    left: 5px;
    margin-top: 5px;
    padding: 3px 5px;
    transform: scale(0.8);
    transform-origin: left top;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    background-color: #fff;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    white-space: nowrap;

    &.isRegisteredWaitingList {
      white-space: normal;
      line-height: 1.4;
      bottom: 0px;
    }

    &.isList {
      display: flex;
      position: static;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      &.hasVacancy {
        .MerchantCalendarCard__Vacancy {
          font-family: 'DIN Alternate', sans-serif;
          font-size: 20px;
        }
      }
    }
  }
}
