@import 'src/styles/variables';

.MerchantResourceSummaryModal {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  .MerchantResourceSummaryModal__Container {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 85%;
    max-width: 600px;
    max-height: 75%;
    transform: translate(-50%, -50%);
    background: white;
  }

  .MerchantResourceSummaryModal__Body {
    padding: 0 12px;
    color: #2d3237;
    font-size: 14px;
  }

  .MerchantResourceSummaryModal__Main {
    overflow-y: scroll;
  }

  .MerchantResourceSummaryModal__ColorPalette {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 3px;
    vertical-align: middle;
  }

  .MerchantResourceSummaryModal__CoverImage {
    img {
      width: 100%;
    }
  }

  .MerchantResourceSummaryModal__Description {
    color: #2d3237;
    font-size: 14px;
    line-height: 1.5;
    word-break: break-all;

    p {
      margin: 1em 0;
    }

    a {
      color: #02bfd3;
    }

    img {
      max-width: 100%;
    }
  }

  .MerchantResourceSummaryModal__Embed {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    iframe {
      width: 100%;
    }
  }

  .MerchantResourceSummaryModal__Footer {
    padding: 12px 12px;
    border-top: 1px solid #dce1e6;
    text-align: right;
  }

  .MerchantResourceSummaryModal__Head {
    height: 14px;
    margin: 16px 0;
  }

  .MerchantResourceSummaryModal__HeadDate {
    float: left;
  }

  .MerchantResourceSummaryModal__Title {
    display: inline;
    font-size: 110%;
    font-weight: 600;
    vertical-align: middle;
  }

  .MerchantResourceSummaryModal__Price {
    p {
      display: block;
    }
  }

  .MerchantResourceSummaryModal__Tags {
    p {
      display: block;
    }
  }
}

.staffMembersWrap {
  margin-top: 48px;
  margin-bottom: 48px;
}

.staffMemberSectionHeading {
  margin-bottom: 24px;
}

.summarizeDateText {
  font-weight: bold;
  font-size: 15px;
  color: $color-black;
  line-height: 1.6;
  margin: 12px 0;
}

.summarizeDateTable {
  border-collapse: collapse;

  th {
    padding: 0 30px 12px;
    font-weight: normal;
    font-size: 15px;
    color: $color-black;
    line-height: 1.6;
    border-bottom: 1px solid $border_gray;
  }
  thead {
    tr {
      border-collapse: collapse;
    }
  }

}
.summarizeDateTableBody {
  tr:first-child {
    td {
      padding-top: 16px;
    }
  }
  td {
    padding: 8px;
    text-align: center;
    label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
}

.cellDisabled {
  opacity: 0.4;
}

.marginTop40 {
  margin-top: 40px;
}