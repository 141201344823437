.overlayBase {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  inset: 0;
  position: fixed;
  transition-property: backdrop-filter, opacity, background-color;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-duration: 300ms;
}
.overlayBaseAfterOpen {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.overlayBaseBeforeClose {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-duration: 150ms;
}

.contentBase {
  box-shadow: 0px 20px 36px -10px rgba(34, 49, 63, 0.2);
  transition: top;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-duration: 300ms;
}

.spContentBase {
  box-shadow: 0px 20px 36px -10px rgba(34, 49, 63, 0.2);
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-duration: 300ms;
  position: fixed;
  top: 100dvh;
}
.spContentAfterOpen {
  top: 0;
}
.spContentBeforeClose {
  top: 100dvh;
}
.contentBaseBeforeClose {
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-duration: 150ms;
}

.pcHeightRestriction {
  /* 100% - modalのmergin - headerのheight */
  max-height: calc(100dvh - 160px - 63px);
}
.mobileHeightRestriction {
  /* 100% - modalのmergin - headerのheight */
  height: calc(100dvh - 16px - 63px);
}

.headerTitle {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
