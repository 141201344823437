@import 'src/styles/variables';

.wrapper {
  background-color: #fff;
  border-radius: 5px;
  list-style: none;
  border: 1px solid $border_gray;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 24px 16px;

  + .listItem {
    border-top: 1px solid $border_gray;
  }
}

.listItemThumb {
  margin-right: 24px;
}

.listItemImg {
  object-fit: cover;
  border: 1px solid $border_gray;
  border-radius: 50px;
}

.listItemName {
  font-weight: bold;
}

.listItemDescription {
  margin-top: 8px;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-wrap;
}
