.MerchantCalendarBody {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 120px);
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #d0d0d0;
  list-style: none;

  .MerchantCalendarBody__Empty {
    box-sizing: border-box;
    min-height: calc(100vh - 120px);
    padding-top: 140px;
    background-color: #f7f7f7;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .MerchantCalendarBody__Item {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    flex-shrink: 0;
    width: 25%;
    border-left: solid 1px #d0d0d0;

    &:first-child {
      border-left: 0;
    }
  }

  .MerchantCalendarBody__ItemHeader {
    position: sticky;
    z-index: 2;
    top: 208px;
    left: 0;
    flex: 0 0 48px;
    height: 70px;
    padding-top: 8px;
    border-bottom: 1px solid #d6d6d6;
    background-color: white;
    color: #333;
    font-family: 'DIN Alternate', sans-serif;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    text-align: center;

    &.isSat {
      color: #009ed8;
    }

    &.isSun {
      color: #eb5353;
    }
  }

  .MerchantCalendarBody__LoaderWrapper {
    height: 65px;
  }

  .MerchantCalendarBody__Loader {
    display: block;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #f7f7f7;
    background-image: url('../../../../../../public/static/loading.gif');
    background-repeat: no-repeat;
    background-position: center 20%;
    background-size: 65px;
  }

  .MerchantCalendarBody__Weekday {
    display: block;
    margin-top: 3px;
    transform: scale(0.9);
    font-size: 10px;
    line-height: 1;
  }

  .MerchantCalendarBody__ItemBody {
    flex: 1 1 auto;
    padding: 7px;
    background-color: #fafafa;
  }

  .MerchantCalendarBody__Closed {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    width: 1em;
    height: 100%;
    margin: 62px auto 0;
    color: #707070;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    text-align: center;
    text-transform: uppercase;
    word-break: break-all;
  }

  &.isList {
    flex-direction: column;

    .MerchantCalendarBody__Item {
      position: static;
      flex-direction: row;
      width: 100%;
      border-top: solid 1px #d0d0d0;
      border-left: 0;

      &:first-child {
        border-top: 0;
      }
    }

    .MerchantCalendarBody__ItemHeader {
      position: static;
      z-index: auto;
      flex: 0 0 48px;
      height: auto;
      padding: 15px 0;
      border-bottom: 0;
    }

    .MerchantCalendarBody__ItemBody {
      border-left: solid 1px #d0d0d0;
    }

    .MerchantCalendarBody__Closed {
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      letter-spacing: 0;
    }
  }
}
