.loading {
  display: block;
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #fff;
  background-image: url('../../../../public/static/loading.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}
