.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0 6px;
}

.button {
  border: none;
  background-color: transparent;
  color: #333;
  font-size: 20px;
  line-height: 1;
  appearance: none;
  cursor: pointer;

  &[disabled] {
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
}

.time {
  color: #333;
  font-family: 'DIN Alternate', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}

.month {
  display: block;
  margin-top: 5px;
  font-size: 20px;
}

.month_number {
  font-size: 30px;
}
