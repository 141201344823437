@import 'src/styles/utils';
@import 'src/styles/variables';

.Tag {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 16px;
  background: $color-brand;
  color: $color-white;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;

  &.isGray {
    background: #b8b8b8;
  }

  > a {
    display: inline-block;
    transition: opacity 0.1s ease;
    color: $color-white;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }
}
