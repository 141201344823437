@import 'src/styles/variables';
@import 'src/styles/utils';

.MerchantTag {
  display: inline-block;
  box-sizing: border-box;
  width: layout-unit(8.5);
  margin: 0 layout-unit(0.25) layout-unit(0.25);
  padding: layout-unit(0.25) layout-unit(0.5);
  font-size: 12px;
  text-align: center;
  vertical-align: middle;

  &.isAccepting {
    border: 1px solid $color-cources-accepting;
    background-color: $color-cources-accepting;
    color: $color-white;
  }

  &.isClosed {
    border: 1px solid #b8b8b8;
    background-color: #b8b8b8;
    color: $color-white;
  }

  &.isCreditCard {
    border: 1px solid $color-cources-prepayment;
    background-color: $color-white;
    color: $color-cources-prepayment;
  }

  &.isOnsite,
  &.isBankTransfer {
    border: 1px solid $color-cources-onsite;
    background-color: $color-white;
    color: $color-cources-onsite;
  }

  &.isTicket {
    border: 1px solid $color-ticketbook;
    background-color: $color-white;
    color: $color-ticketbook;
  }

  &.isSubscription {
    border: 1px solid $color-subscription;
    background-color: $color-white;
    color: $color-subscription;
  }

  &.isRequireAcceptance {
    border: 1px solid $color-cources-require-acceptance;
    background-color: $color-white;
    color: $color-cources-require-acceptance;
  }
}
